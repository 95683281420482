import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  MdKeyboardArrowLeft,
  MdOutlineDateRange,
  MdOutlineMail,
} from "react-icons/md";
import { FaChevronDown, FaMinus, FaPhoneAlt, FaPlus } from "react-icons/fa";
import Modal from "../../components/Modal/Modal";
import { IoCloseOutline } from "react-icons/io5";
import IconMessage from "../../assets/message.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

let tzlocal = dayjs.tz.guess();
dayjs.tz.setDefault(tzlocal);

function BookingDetails() {
  const { state } = useLocation();
  // const booking = state?.booking;
  const bookingState = state?.booking;
  const bookingId = bookingState?.serviceUuid;
  const [booking, setBooking] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(booking?.adminStatus || "");
  const [selectedStatus, setSelectedStatus] = useState(
    booking?.adminStatus || ""
  );

  const [formData, setFormData] = useState({
    comment: "",
    amountDetails: [
      {
        amount: 0,
        description: "",
      },
    ],
  });
  const [allComments, setAllComments] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [personName, setPersonName] = useState("");
  const [imeiOrSlNo, setImeiOrSlNo] = useState("");
  const [deliveryAgent, setDeliveryAgent] = useState("");

  const fetchStatuses = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}:8070/master-service/api/repair-status/all`,
    };

    try {
      const { data } = await axios.request(options);
      console.log(data);

      setStatuses(data);
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleStatusChange = (newStatus) => {
    if (newStatus === "Diagnose Completed") {
      setIsModalOpen(true);
    } else {
      setFormData({
        comment: "",
        amountDetails: [{ amount: 0, description: "" }],
      });
      setIsSecondModalOpen(true);
      updateStatus(newStatus);
    }
  };

  const fetchComments = async () => {
    try {
      const authToken = sessionStorage.getItem("adminAuthToken");
      const response = await axios.get(
        `https://apiv1.gadgetsreborn.com:8899/booking-service/admin/comments?bookingId=${bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAllComments(response.data.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch comments");
      setLoading(false);
    }
  };

  const fetchBooking = async () => {
    try {
      const authToken = sessionStorage.getItem("adminAuthToken");
      const response = await axios.get(
        `https://apiv1.gadgetsreborn.com:8899/booking-service/service-request/booking-details?bookingUUid=${bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setLoading(false);
      setBooking(response.data.data);
      setSelectedStatus(response.data.data.adminStatus);
    } catch (error) {
      setError("Failed to fetch comments");
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    // if (!formData?.comment) return toast.error("Please add a comment");

    if (selectedStatus === "Delivery Completed") {
      if (!deliveryAgent) {
        return toast.error("Please provide Delivery Agent name");
      }
    }

    // Validate required fields for Pickup Completed status
    if (selectedStatus === "Pickup Completed") {
      if (!personName || !imeiOrSlNo) {
        return toast.error(
          "Please provide Technician Name and IMEI/Serial Number"
        );
      }
    }

    if (selectedStatus === "Diagnose Completed") {
      for (let i = 0; i < formData?.amountDetails?.length; i++) {
        if (!formData?.amountDetails[i]?.description) {
          return toast.error("Please add description for each amount");
        }
      }
    }
    const previousStatus = status;
    setStatus(selectedStatus);
    setIsSecondModalOpen(false);

    const authToken = sessionStorage.getItem("adminAuthToken");

    let dataObj = {
      bookingId: bookingId,
      status: selectedStatus,
      comment: formData.comment,
      personName:
        selectedStatus === "Pickup Completed"
          ? personName
          : selectedStatus === "Delivery Completed"
          ? deliveryAgent
          : undefined,
      imeiOrSlNo:
        selectedStatus === "Pickup Completed" ? imeiOrSlNo : undefined,
    };

    if (selectedStatus === "Diagnose Completed") {
      dataObj.feeChangeRequests = formData.amountDetails;
    }

    const options = {
      method: "PUT",
      url: `${process.env.REACT_APP_BASE_URL}:8899/booking-service/admin/update-status-repair`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: dataObj,
    };

    try {
      const { data } = await axios.request(options);
      if (data.status === 200) {
        toast.success(data.msg || "Status updated successfully");
        fetchComments();
        fetchBooking();
      } else {
        throw new Error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error(
        error?.response?.data?.msg || "Cannot update payment-related status"
      );
      setStatus(previousStatus);
    }
  };

  const getOptionDisableOption = (item, index) => {
    console.log(
      statuses.findIndex((s) => s.status === selectedStatus),
      index + 1
    );
    if (item === "Repair Estimation Paid") return true;
    if (item === "BookingFee Payment Pending") return true;
    if (statuses.findIndex((s) => s.status === selectedStatus) === index) {
      return false;
    } else if (
      statuses.findIndex((s) => s.status === selectedStatus) ===
      index + 1
    ) {
      return false;
    } else if (
      statuses.findIndex((s) => s.status === selectedStatus) ===
      index - 1
    ) {
      return false;
    } else return true;
  };

  const handleStatusChange2 = (newStatus) => {
    setSelectedStatus(newStatus); // store selected status in a temporary state
    setFormData({
      comment: "",
      amountDetails: [{ amount: 0, description: "" }],
    });
    setIsSecondModalOpen(true); // open modal for adding comment
  };

  // Handle comment addition and status update
  const handleAddComment = () => {
    updateStatus(); // call updateStatus to send the API request
  };

  // Close the modal
  const handleSecondModalClose2 = () => {
    setIsSecondModalOpen(false);
  };

  useEffect(() => {
    fetchComments();
    fetchBooking();
  }, [bookingId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <Modal open={isSecondModalOpen} onOpenChange={handleSecondModalClose2}>
        <div className="max-h-[95vh] overflow-y-auto">
          <div className="flex justify-center relative">
            <img src={IconMessage} className="w-12 h-12 pt-2" alt="" />
            <button
              onClick={handleSecondModalClose2}
              className="absolute top-0 right-0"
            >
              <IoCloseOutline size={24} />
            </button>
          </div>
          {selectedStatus === "Pickup Completed" && (
            <>
              <div className="flex flex-col gap-2 mt-5 mb-3">
                <label className="font-semibold text-lg">Technician Name</label>
                <input
                  type="text"
                  value={personName}
                  onChange={(e) => setPersonName(e.target.value)}
                  placeholder="Technician Name"
                  className="w-full border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                />
              </div>
              <div className="flex flex-col gap-2 mt-5">
                <label className="font-semibold text-lg">
                  IMEI/Serial Number
                </label>
                <input
                  type="text"
                  value={imeiOrSlNo}
                  onChange={(e) => setImeiOrSlNo(e.target.value)}
                  placeholder="IMEI/Serial Number"
                  className="w-full border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                />
              </div>
            </>
          )}
          {selectedStatus === "Delivery Completed" && (
            <div className="flex flex-col gap-2 mt-5 mb-3">
              <label className="font-semibold text-lg">Delivery Agent</label>
              <input
                type="text"
                value={deliveryAgent}
                onChange={(e) => setDeliveryAgent(e.target.value)}
                placeholder="Delivery Agent Name"
                className="w-full border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
              />
            </div>
          )}
          {selectedStatus === "Diagnose Completed" ? null : (
            <div className="mt- text-lg font-semibold text-start">
              Add comment
            </div>
          )}
          {selectedStatus === "Diagnose Completed" ? (
            <div className="flex flex-col gap-2 mt-5">
              {formData?.amountDetails?.map((amountDetail, index) => (
                <>
                  <div className="flex flex-col gap-2">
                    <label className="font-semibold text-lg" htmlFor="">
                      Add Amount
                    </label>
                    <div className="flex gap-2 w-full">
                      <div className="grow">
                        <input
                          type="number"
                          name="amount"
                          value={amountDetail?.amount}
                          onChange={(e) => {
                            // Create a shallow copy of the amountDetails array from formData
                            const arr = [...formData.amountDetails];

                            // Ensure the element at the specified index exists; if not, initialize it
                            if (!arr[index]) {
                              arr[index] = { amount: "", description: "" }; // Initialize with an object with 'amount' key
                            }

                            // Assign the new value to the 'amount' property
                            arr[index].amount = e.target.value;

                            // Update the state with the modified array
                            setFormData((prev) => ({
                              ...prev,
                              amountDetails: arr,
                            }));
                          }}
                          placeholder="Amount"
                          className="w-full border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                        />
                      </div>
                      {index === formData.amountDetails.length - 1 ? (
                        <>
                          <button
                            onClick={() => {
                              const newAmountDetails = [
                                ...formData.amountDetails,
                                { amount: 0, description: "" },
                              ];
                              console.log(newAmountDetails);
                              setFormData((prev) => ({
                                ...prev,
                                amountDetails: newAmountDetails,
                              }));
                            }}
                            className="bg-[#E4B703] text-black rounded-md h-[57px] aspect-square | flex items-center justify-center"
                          >
                            <FaPlus className="text-white" />
                          </button>
                          <button
                            onClick={() => {
                              if (formData.amountDetails.length === 1) {
                                return;
                              }
                              const newAmountDetails =
                                formData.amountDetails.filter(
                                  (item, indexOfItem) => index !== indexOfItem
                                );
                              setFormData((prev) => ({
                                ...prev,
                                amountDetails: newAmountDetails,
                              }));
                            }}
                            className={`bg-[#E4B703] text-black rounded-md h-[57px] aspect-square | flex items-center justify-center ${
                              formData.amountDetails.length === 1
                                ? "opacity-50 cursor-not-allowed"
                                : ""
                            }`}
                          >
                            <FaMinus className={`text-white`} />
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 mb-5">
                    <label className="font-semibold text-lg" htmlFor="">
                      Add Description
                    </label>
                    <textarea
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={(e) => {
                        // Create a shallow copy of the amountDetails array from formData
                        const arr = [...formData.amountDetails];

                        // Ensure the element at the specified index exists; if not, initialize it
                        if (!arr[index]) {
                          arr[index] = { amount: "", description: "" }; // Initialize with an object with 'amount' key
                        }

                        // Assign the new value to the 'amount' property
                        arr[index].description = e.target.value;

                        // Update the state with the modified array
                        setFormData((prev) => ({
                          ...prev,
                          amountDetails: arr,
                        }));
                      }}
                      placeholder="Description"
                      maxLength={100}
                      className="w-full focus:outline-none border border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                    />
                  </div>
                </>
              ))}

              <div className="flex flex-col gap-2">
                <label className="font-semibold text-lg" htmlFor="">
                  Add Comment
                </label>
                <textarea
                  type="text"
                  name="comment"
                  value={formData.comment}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }));
                  }}
                  placeholder="Comment"
                  className="w-full border focus:outline-none border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5"
                />
              </div>
            </div>
          ) : (
            <textarea
              type="text"
              name="comment"
              value={formData.comment}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  comment: e.target.value,
                }));
              }}
              placeholder="Comment"
              className="w-full border focus:outline-none border-[#E4B703] rounded-md p-2 bg-white h-[57px] px-5 mt-5"
            />
          )}

          <button
            onClick={updateStatus}
            className="mt-8 w-full py-2.5 rounded-lg font-bold text-black bg-[#E4B703]"
          >
            Add
          </button>
        </div>
      </Modal>

      <ToastContainer />
      <div className="grid grid-cols-4 gap-6">
        <div className="sm:block hidden col-span-3">
          <button
            onClick={() => window.history.back()}
            className="hidden whitespace-nowrap px-[10px] py-[5px] sm:flex items-center justify-center gap-2 bg-white border border-gray-300 rounded-md shadow-sm"
          >
            <span className="">
              <MdKeyboardArrowLeft />
            </span>
            <span className="font-bold font-plus-jakarta-sans text-[14px]">
              Go Back
            </span>
          </button>
          <div className="mt-4">
            <div className="border rounded-[24px] shadow py-4 px-6">
              <div className="flex gap-[14px]">
                <div>
                  <h3 className="text-[#101828] text-[18px] font-semibold">
                    {booking?.address?.name || "N/A"}
                  </h3>
                  <p className="text-[#475467] text-sm">
                    ID: {bookingId || "N/A"}
                  </p>
                </div>
              </div>
              <div className="mt-4 flex gap-[42px]">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <MdOutlineMail size={18} color="#E4B703" />
                    <h3 className="text-sm font-bold text-[#475467]">Email</h3>
                  </div>
                  <p className="text-[12px] text-[#475467]">
                    {booking?.address?.email || "N/A"}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <FaPhoneAlt size={16} color="#E4B703" />
                    <h3 className="text-sm font-bold text-[#475467]">
                      Phone Number
                    </h3>
                  </div>
                  <p className="text-[12px] text-[#475467]">
                    {booking?.address?.mobileNo || "N/A"}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="flex gap-[6px]">
                    <MdOutlineDateRange size={18} color="#E4B703" />
                    <h3 className="text-sm font-bold text-[#475467]">Date</h3>
                  </div>
                  <p className="text-[12px] text-[#475467]">
                    {new Date(booking.createdAt).toLocaleDateString()}
                  </p>
                </div>
                <div className="relative inline-block text-left">
                  <select
                    value={selectedStatus}
                    onChange={(e) => handleStatusChange2(e.target.value)}
                    className="inline-flex gap-[6px] items-center justify-center w-[180px] rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600 focus:outline-none"
                  >
                    {statuses.map((item, index) => (
                      <option
                        disabled={getOptionDisableOption(item.status, index)}
                        key={item.id}
                        value={item.status}
                        className="text-gray-700"
                      >
                        {item.status}
                      </option>
                    ))}
                  </select>
                </div>
                {booking.adminStatus === "Pickup Completed" && (
                  <div>
                    <button
                      onClick={() =>
                        window.open(
                          "https://gruserdocs.s3.ap-south-1.amazonaws.com/PickupDocs/Pickup_detailsGR001AA0100421.pdf",
                          "_blank"
                        )
                      }
                      className="flex items-center justify-center whitespace-nowrap bg-[#fbc902] font-semibold rounded-md text-xs px-2 py-3 text-[#344054]"
                    >
                      Download PDF
                    </button>
                  </div>
                )}
                {booking.adminStatus === "Delivery Completed" && (
                  <div>
                    <button
                      onClick={() =>
                        window.open(
                          "https://gruserdocs.s3.ap-south-1.amazonaws.com/DeliveryDocs/Delivery_detailsGR001AA0100290.pdf",
                          "_blank"
                        )
                      }
                      className="flex items-center justify-center whitespace-nowrap bg-[#fbc902] font-semibold rounded-md text-xs px-2 py-3 text-[#344054]"
                    >
                      Download PDF
                    </button>
                  </div>
                )}
              </div>

              <div className="mt-4 border-t-[2px]">
                <div className="mt-4">
                  <h3 className="text-sm text-[#475467] font-bold">
                    Device details
                  </h3>
                  <div className="mt-[8px]">
                    <h3 className="text-[#101828] text-[16px] font-bold">
                      {booking?.modelName || "N/A"}
                    </h3>
                    <p className="text-[12px] text-[#344054]">
                      Type:
                      <span className="font-semibold">
                        {" "}
                        {booking?.categoryName || "N/A"}
                      </span>
                    </p>
                    <p className="text-[12px] text-[#344054]">
                      Brand:
                      <span className="font-semibold">
                        {" "}
                        {booking?.brandName || "N/A"}
                      </span>
                    </p>
                    <p className="text-[12px] text-[#344054]">
                      Series:
                      <span className="font-semibold">
                        {" "}
                        {booking?.modelName || "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1 ">
                <p className="font-bold text-sm text-[#475467]">Description</p>
                <h3 className="text-[#101828] text-[16px] font-bold mt-1">
                  {booking?.defectName || "N/A"}
                </h3>
              </div>
            </div>
          </div>
          <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1 ">
                <p className="font-bold text-sm text-[#475467]">Address</p>
                <p className="text-[12px] text-[#101828]  mt-1">
                  {booking?.address?.apartment}, {booking?.address?.landMark},{" "}
                  {booking?.address?.locality}, {booking?.address?.zipCode}
                </p>
              </div>
            </div>
          </div>
          <div className="border rounded-[24px] shadow py-4 px-6 mt-4">
            <p className="font-bold text-sm text-[#475467]">Payment details</p>
            <div className="flex justify-between">
              <p className="text-[12px]">Estimated Repair Fee </p>
              <p className="text-[12px] font-semibold">
                {booking?.totalAmount || "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Discount</p>

              <p className="text-[12px] font-semibold">
                {booking?.amountPaid === null ||
                booking?.amountPaid === 0 ||
                booking?.amountPaid === "N/A"
                  ? "N/A"
                  : booking?.totalAmount - booking?.amountPaid}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Booking Fee / Diagnostic Fee</p>
              <p className="text-[12px] font-semibold">
                {booking?.amountPaid || "N/A"}
              </p>
            </div>
            <div className="mt-4 border-t-[2px] py-4">
              <div className="flex justify-between">
                <p>Amount to be paid</p>
                <h3 className=" text-[28px]">{booking?.amountPaid || "N/A"}</h3>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end mt-4"></div>
        </div>
        <div className="col-span-1 sm:block hidden">
          <div className="border-l border-[#D0D5DD] | pl-8 pr-4 mt-[56px] | flex flex-col gap-4 | w-full h-full">
            <h6 className="text-xs">Comments</h6>
            <div className="flex flex-col gap-6">
              {allComments?.map((comment, index) => (
                <div
                  key={index}
                  className={`flex flex-col gap-2 | pb-2 | ${
                    index !== comment.length - 1
                      ? "border-b border-[#D0D5DD]"
                      : ""
                  }`}
                >
                  <div className="flex items-center justify-between gap-1.5 flex-wrap">
                    <h6 className="text-[13px] font-semibold">
                      {comment.adminName}
                    </h6>
                    <div className="text-[#FBC903] text-[10px] font-medium rounded-full border-2 border-[#FBC903] py-[1px] px-[4px] whitespace-nowrap">
                      {comment.stepWill}
                    </div>
                  </div>
                  <div className="flex gap-2 items-center text-[12px] font-medium text-[#D0D5DD] mt-1">
                    <p>
                      {dayjs
                        .utc(comment.createdAt)
                        .tz(dayjs.tz.guess())
                        .format("DD MMM YYYY")}
                    </p>
                    <p>
                      {dayjs
                        .utc(comment.createdAt)
                        .tz(dayjs.tz.guess())
                        .format("hh:mm A")}
                    </p>
                    {/* <p>
                      {new Date(comment.createdAt).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </p> */}
                  </div>
                  <p className="text-sm">{comment.comment}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden block">
        <div className="bg-white p-2 rounded-md shadow border">
          <div className="flex gap-[14px] px-2">
            <div>
              <h3 className="text-[#101828] text-[18px] font-semibold">
                {booking?.address?.name || "N/A"}
              </h3>
              <p className="text-[#101828] text-sm">ID: {bookingId || "N/A"}</p>
            </div>
          </div>
          <div className="flex flex-col items-start mt-[20px] gap-1 px-2">
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineMail size={18} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {booking?.address?.email || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <FaPhoneAlt size={16} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {booking?.address?.mobileNo || "N/A"}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="flex gap-[6px]">
                <MdOutlineDateRange size={18} color="#E4B703" />
                <p className="text-[12px] text-[#101828]">
                  {new Date(booking.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
          <div className="relative inline-block mt-5 mx-auto w-full px-2">
            <div className="">
              <select
                value={selectedStatus}
                onChange={(e) => handleStatusChange2(e.target.value)}
                className="inline-flex text-center gap-[6px] items-center justify-center w-full rounded-md border border-yellow-400 shadow-sm px-4 py-2 bg-white text-sm font-medium text-yellow-600 focus:outline-none"
              >
                {statuses.map((item, index) => (
                  <option
                    disabled={getOptionDisableOption(item.status, index)}
                    key={item.id}
                    value={item.status}
                    className="text-gray-700"
                  >
                    {item.status}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {booking.adminStatus === "Pickup Completed" && (
            <div className="px-2 mt-2">
              <button
                onClick={() =>
                  window.open(
                    "https://gruserdocs.s3.ap-south-1.amazonaws.com/PickupDocs/Pickup_detailsGR001AA0100421.pdf",
                    "_blank"
                  )
                }
                className="flex w-full items-center justify-center whitespace-nowrap bg-[#fbc902] font-semibold rounded-md text-xs px-2 py-3 text-[#344054]"
              >
                Download PDF
              </button>
            </div>
          )}
          {booking.adminStatus === "Delivery Completed" && (
            <div className="px-2 mt-2">
              <button
                onClick={() =>
                  window.open(
                    "https://gruserdocs.s3.ap-south-1.amazonaws.com/DeliveryDocs/Delivery_detailsGR001AA0100290.pdf",
                    "_blank"
                  )
                }
                className="flex w-full items-center justify-center whitespace-nowrap bg-[#fbc902] font-semibold rounded-md text-xs px-2 py-3 text-[#344054]"
              >
                Download PDF
              </button>
            </div>
          )}
          <div className="py-5 border-b-[2px] px-2">
            <div>
              <h3 className="text-sm text-[#475467] font-bold">
                Device details
              </h3>
              <div className="mt-[8px]">
                <h3 className="text-[#101828] text-[16px] font-bold">
                  {booking?.modelName || "N/A"}
                </h3>
                <p className="text-[12px] text-[#344054]">
                  Type:
                  <span className="font-semibold">
                    {" "}
                    {booking?.categoryName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Brand:
                  <span className="font-semibold">
                    {" "}
                    {booking?.brandName || "N/A"}
                  </span>
                </p>
                <p className="text-[12px] text-[#344054]">
                  Series:
                  <span className="font-semibold">
                    {" "}
                    {booking?.modelName || "N/A"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="py-5 border-b-[2px] px-2">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1">
                <p className="font-bold text-sm text-[#475467]">Description</p>
                <h3 className="text-[#101828] text-[16px] font-bold mt-1">
                  {booking?.defectName || "N/A"}
                </h3>
              </div>
            </div>
          </div>

          <div className="py-5 border-b-[2px] px-2">
            <div className="grid grid-cols-2 items-center justify-center">
              <div className="col-span-1">
                <p className="font-bold text-sm text-[#475467]">Address</p>
                <p className="text-[12px] text-[#101828]  mt-1">
                  {booking?.address?.apartment}, {booking?.address?.landMark},{" "}
                  {booking?.address?.locality}, {booking?.address?.zipCode}
                </p>
              </div>
            </div>
          </div>
          <div className="px-2 py-5">
            <p className="font-bold text-sm text-[#475467]">Payment details</p>
            <div className="flex justify-between">
              <p className="text-[12px]">Total </p>
              <p className="text-[12px] font-semibold">
                {booking?.totalAmount || "N/A"}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Discount</p>
              <p className="text-[12px] font-semibold ">
                {booking?.amountPaid === null ||
                booking?.amountPaid === 0 ||
                booking?.amountPaid === "N/A"
                  ? "N/A"
                  : booking?.totalAmount - booking?.amountPaid}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[12px]">Booking Fee / Diagnostic Fee</p>
              <p className="text-[12px] font-semibold">
                {booking?.amountPaid || "N/A"}
              </p>
            </div>
            <div className="mt-4 border-t-[2px] py-4">
              <div className="flex justify-between">
                <p>Amount to be paid</p>
                <h3 className=" text-[18px]">{booking?.amountPaid || "N/A"}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-1 sm:hidden block">
        <div className="border rounded-md shadow | p-4 mt-[24px] | flex flex-col gap-4 | w-full h-full">
          <h6 className="text-xs">Comments</h6>
          <div className="flex flex-col gap-6">
            {allComments.map((comment, index) => (
              <div
                key={index}
                className={`flex flex-col gap-2 | pb-2 | ${
                  index !== comment.length - 1
                    ? "border-b border-[#D0D5DD]"
                    : ""
                }`}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <h6 className="text-[13px] font-semibold">
                      {comment.adminName}
                    </h6>
                    <div className="text-[#FBC903] text-[10px] font-medium rounded-full border-2 border-[#FBC903] py-[1px] px-[4px]">
                      {comment.stepWill}
                    </div>
                  </div>
                  <div className="flex gap-2 items-center text-[12px] font-medium text-[#D0D5DD]">
                    <p>
                      {dayjs
                        .utc(comment.createdAt)
                        .tz(dayjs.tz.guess())
                        .format("DD MMM YYYY")}
                    </p>
                    <p>
                      {dayjs
                        .utc(comment.createdAt)
                        .tz(dayjs.tz.guess())
                        .format("hh:mm A")}
                    </p>
                  </div>
                </div>
                <p className="text-sm">{comment.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingDetails;
